import {
  getRedialCalls,
} from "@/features/Redial/GetRedialCalls";
import {
  updateAiRedialCalls,
  updateManualRedialCalls,
} from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useCompanyConfig } from "./useCompanyConfig";
import { CallResult, callResults } from "@/models/CallHistory";
import dayjs from "dayjs";

const DEFAULT_REDIAL_INTERVAL_DAYS = 5;
const DEFAULT_REDIAL_MAX_ATTEMPTS = 3;

let fetching = false;

export const useRedialCalls = ({
  disableLoading = false,
}: {
  disableLoading?: boolean;
} = {}) => {
  const {
    tenantName,
    callResultsToAiRedial,
    callResultsToManualRedial,
    redialIntervalDays,
    redialAttempts: redialMaxAttempts,
  } = useCompanyConfig();
  const isSignedIn = useSelector(
    (state: RootState) => state.user.loggedInUser.online,
  );
  const manualRedialCalls = useSelector(
    (state: RootState) => state.call.manualRedialCalls,
  );
  const aiRedialCalls = useSelector(
    (state: RootState) => state.call.aiRedialCalls,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetch = async () => {
      if (!isSignedIn || fetching || !tenantName) return;
      if (
        Object.keys(manualRedialCalls).length ||
        Object.keys(aiRedialCalls).length
      )
        return;
      fetching = true;

      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: true }));

      try {
        // 再架電間隔と再架電最大回数 (初期値は5日間隔で3回)
        const [manual, ai] = await Promise.all([
          getRedialCalls({
            callResultsToRedial: callResultsToManualRedial as CallResult[],
            redialMaxAttempts: {
              ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_MAX_ATTEMPTS])),
              ...(redialMaxAttempts as unknown as Record<CallResult, number>),
            }
          }),
          getRedialCalls({
            callResultsToRedial: callResultsToAiRedial as CallResult[],
            redialIntervalDays: {
              ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_INTERVAL_DAYS])),
              ...redialIntervalDays,
            },
            redialMaxAttempts: {
              ...Object.fromEntries(callResults.map((result) => [result, DEFAULT_REDIAL_MAX_ATTEMPTS])),
              ...(redialMaxAttempts as unknown as Record<CallResult, number>),
            },
          }),
        ]);
        dispatch(
          updateManualRedialCalls(
            manual.filter((history) => {
              return (
                history.nextCallScheduledAt &&
                dayjs() > dayjs(history.nextCallScheduledAt)
              );
            }),
          ),
        );
        dispatch(updateAiRedialCalls(ai));

        console.log("Fetched redial calls successfully");
      } catch (error) {
        console.error("Error fetching redial calls:", error);
      } finally {
        dispatch(setLoadingBackdrop({ key: "fetchRedialCalls", state: false }));
      }

      if (!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: false }));
      fetching = false;
    };
    fetch();
  }, [
    dispatch,
    isSignedIn,
    disableLoading,
    manualRedialCalls,
    aiRedialCalls,
    tenantName,
    callResultsToManualRedial,
    redialMaxAttempts,
    callResultsToAiRedial,
    redialIntervalDays
  ]);

  return { manualRedialCalls, aiRedialCalls };
};
