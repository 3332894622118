import { Box, Container, Divider, Fade, Skeleton, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import React, { useEffect, useMemo } from "react";
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import RedialManual from "@/features/Redial/RedialManual";
import RedialAi from "@/features/Redial/RedialAi";
import { useRedialCalls } from "@/hooks/useRedialCalls";
import { useCompanyPhoneNumbers } from "@/hooks/useCompanyPhoneNumbers";

const Redial = React.memo(() => {
  const navigate = useNavigate();
  const roles = useSelector(
    (state: RootState) => state.user.loggedInUser.roles,
  );
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const { tenantId } = useParams();
  const { aiRedialCalls, manualRedialCalls } = useRedialCalls();
  const companyPhoneNumbers = useCompanyPhoneNumbers();
  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [{ title: "再コール" }],
    [],
  );
  const loading = useSelector(
    (state: RootState) => state.common.loadingBackdrop.fetchNurturingCalls,
  );
  const showSkeleton = useMemo(
    () => (!roles.includes("ADMIN") && !roles.includes("SV")) || loading,
    [roles, loading],
  );

  // ADMINかSVでないならhomeへ遷移
  useEffect(() => {
    if (!isSignedIn || !roles) return;
    if (!roles.includes("ADMIN") && !roles.includes("SV"))
      navigate(`/${tenantId}`);
  }, [isSignedIn, roles, tenantId]);

  return (
    <Container sx={{ py: 4, maxWidth: 900, mx: "auto" }}>
      <Box mb={2}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      <Box width={722} mx="auto">
        {/* <Tabs value="manual" sx={{ mb: 2 }}>
          <Tab label="手動コール" value="manual" />
          <Tab label="AIコール" value="ai" />
        </Tabs> */}
        {showSkeleton ? (
          <Stack maxWidth={786} mx="auto" gap={4}>
            <Skeleton
              height={607}
              variant="rectangular"
              animation="wave"
            ></Skeleton>
            <Skeleton
              height={607}
              variant="rectangular"
              animation="wave"
            ></Skeleton>
          </Stack>
        ) : (
          <Stack gap={4}>
            <Fade in={!showSkeleton}>
              <div>
                <RedialManual
                  nurturingCalls={Object.values(manualRedialCalls)}
                ></RedialManual>
              </div>
            </Fade>
            <Divider></Divider>
            <Fade in={!showSkeleton}>
              <div>
                <RedialAi
                  nurturingCalls={Object.values(aiRedialCalls)}
                  companyPhoneNumbers={companyPhoneNumbers}
                ></RedialAi>
              </div>
            </Fade>
          </Stack>
        )}
      </Box>
    </Container>
  );
});

export default Redial;
