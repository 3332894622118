import { UserState } from "@/models/User";

const callStateBgColors: {
  [state in UserState]: string;
} = {
  IN_CALL: "#E53935", // red
  IN_CALL_WRAP_UP: "#E53935", // red
  ASSIGNED_TO_CALL_BATCH: "#FBC02D", // yellow
  FREE: "#7CB342", // green
  AWAY: "#1E88E5", // blue
  AWAY_REQUESTED: "#1E88E5", // blue
} as const;

export default callStateBgColors;
