import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { CallWithRedialAttempts } from "@/models/Call";
import { request } from "@/models/telai-backend/client";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import {
  CallOutlined,
  CheckOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const manualRedialColumns: GridColDef[] = [
  { field: "companyName", headerName: "会社名", width: 150 },
  // { field: "phoneNumber", headerName: "電話番号", width: 100 },
  { field: "callResult", headerName: "コール結果", width: 160 },
  { field: "createdAt", headerName: "通話日付", width: 120 },
  {
    field: "nextCallDate",
    headerName: "次回架電予定",
    width: 120,
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      !params.value || params.value === "1970-01-01" ? "未設定" : params.value,
  },
  // { field: "operatorId", headerName: "担当者", width: 120 },
  { field: "redialAttempts", headerName: "再コール回数", width: 120 },
] as const;

const RedialManual = React.memo(
  ({
    nurturingCalls,
  }: {
    nurturingCalls: CallWithRedialAttempts[]
  }) => {
    const callResultLabels = useSelector(
      (state: RootState) => state.company.config.customCallResultLabels,
    );
    const users = useCompanyUsers();
    const { tenantId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    // 手動再コール
    const [manualRedialDialog, setManualRedialDialog] = useState(false);
    const [manualRedialSelection, setManualRedialSelection] = useState([]);
    const [nextCallCompleted, setNextCallCompleted] = useState<{ [callId: string]: boolean }>({});

    // 選択されたコール履歴を再コール済みとしてマークする
    const handleRegisterNextCalls = useCallback(async () => {
      await request({
        path: "/calls/{callId}/note",
        httpMethod: "put",
        params: {
          paths: { callId: manualRedialSelection[0] },
          body: {
            nextCallCompleted: true
          }
        },
      })
      setNextCallCompleted((prev) => ({ ...prev, [manualRedialSelection[0]]: true }));

      setManualRedialDialog(false);
      dispatch(
        setLoadingBackdrop({ key: "handleRegisterNextCalls", state: true }),
      );
      try {
        dispatch(
          setSnackbar({
            text: "正常に登録しました。",
            open: true,
            severity: "success",
          }),
        );
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "登録処理中にエラーが発生しました。",
            open: true,
            severity: "error",
          }),
        );
      } finally {
        dispatch(
          setLoadingBackdrop({ key: "handleRegisterNextCalls", state: false }),
        );
      }
    }, [manualRedialSelection, nurturingCalls]);

    const manualRedialRows = useMemo(
      () =>
        nurturingCalls
          .filter((call) => !nextCallCompleted[call.id])
          .map((call) => ({
            id: call.id,
            companyName: call.toContactName,
            phoneNumber: call.to,
            createdAt: call.createdAt,
            nextCallDate: call.nextCallScheduledAt,
            callResult: callResultLabels[call.noteResult] || call.noteResult,
            operatorId: users[call.assigneeId]?.name || "---",
            redialAttempts: call.redialAttempts + "回",
          }))
          .sort((a, b) => b.nextCallDate.localeCompare(a.nextCallDate)),
      [nurturingCalls, users],
    );

    const manualCallNavigateUrl = useMemo(() => {
      const phoneNumber = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0],
      )?.phoneNumber;
      // const ids = manualRedialRows.find((row) => row.id === manualRedialSelection[0])?.prevCallSids.join(",")
      const ids = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0],
      )?.id;
      if (!phoneNumber) return `/${tenantId}/manualcall`;
      return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${ids ? `&ids=${ids}` : ""}`;
    }, [manualRedialRows, manualRedialSelection, tenantId]);

    return (
      <Box sx={{ mx: "auto", position: "relative", display: "inline-block" }}>
        <Stack direction="row" mb={2}>
          <Stack alignItems="center" direction="row">
            <CallOutlined sx={{ mr: 1 }}></CallOutlined>
            <Typography textAlign="left" fontWeight="600" fontSize="1.1rem">
              手動再コール
            </Typography>
          </Stack>
        </Stack>
        <Stack alignContent="center" flexWrap="wrap" gap={2}>
          <Paper sx={{ overflow: "hidden" }}>
            <DataGrid
              columns={manualRedialColumns}
              rows={manualRedialRows}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              sx={{
                scrollbarColor: "#ddd #f6f6f6",
                scrollbarWidth: "thin",
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              rowHeight={36}
              checkboxSelection
              rowSelectionModel={manualRedialSelection}
              onRowSelectionModelChange={(model) =>
                setManualRedialSelection(model)
              }
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            ></DataGrid>
          </Paper>
          <Stack direction="row" justifyContent="end" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<PhoneOutlined></PhoneOutlined>}
              disabled={manualRedialSelection.length !== 1}
              onClick={() => navigate(manualCallNavigateUrl)}
            >
              手動コールページへ移動
            </Button>

            <Button
              variant="contained"
              startIcon={<CheckOutlined></CheckOutlined>}
              disabled={manualRedialSelection.length < 1}
              onClick={() => setManualRedialDialog(true)}
            >
              {manualRedialSelection.length}件を再コール済みとして登録
            </Button>
          </Stack>
        </Stack>

        <Dialog open={manualRedialDialog}>
          <DialogTitle display="flex" gap={1} alignItems="center">
            <CheckOutlined></CheckOutlined>
            再コール登録
          </DialogTitle>
          <DialogContent>
            <Typography>
              {manualRedialSelection.length}
              件の項目を再コール済みとして登録しますか？
            </Typography>
            <Typography>
              再コール済みとして登録された項目は再コールリストから除外されます。
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setManualRedialDialog(false)}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleRegisterNextCalls()}
            >
              登録
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  },
);

export default RedialManual;
