import { getScriptLines } from "@/store/callSlice";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useScripts } from "./useScripts";

export const useScriptLines = (scriptId: string) => {
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId,
  );
  const dispatch = useDispatch<AppDispatch>();
  const scripts = useScripts();
  const scriptLines = useSelector(
    (state: RootState) => state.call.scriptLines[scriptId],
  );
  const selectedScriptLines = useMemo(() => {
    if (!scriptLines || !scripts) return;
    const selectedLines = scripts[scriptId]?.selectedLines;
    if (selectedLines) {
      const selectedScriptLines = {};
      Object.entries(selectedLines).forEach(([alphabet, index]) => {
        selectedScriptLines[alphabet] = scriptLines[alphabet][index];
      });
      return selectedScriptLines;
    } else {
      const retScriptLines = {};
      Object.entries(scriptLines).forEach(([alphabet, lines]) => {
        retScriptLines[alphabet] = lines[0];
      });
      return retScriptLines;
    }
  }, [scriptLines, scripts]);

  useEffect(() => {
    if (!scriptId || !scripts) return;
    const fetch = async () => {
      dispatch(setLoadingBackdrop({ key: "useScriptLine", state: true }));
      await dispatch(getScriptLines({ scriptId }));
      dispatch(setLoadingBackdrop({ key: "useScriptLine", state: false }));
    };
    fetch();
  }, [scriptId, scripts]);

  return selectedScriptLines || {};
};
