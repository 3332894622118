import {
  CallHistoryRecord,
  CallResult,
  ManualOutboundCallResult,
  CallDirection,
} from "@/models/CallHistory";
import { request } from "@/models/telai-backend/client";
import { Dayjs } from "dayjs";

const DEFAULT_QUERY_LIMIT = 50;

type CallHistoryFilter = {
  startDate?: Dayjs | null;
  callHistoryId?: string;
  nextCallStartDate?: Dayjs | null;
  nextCallEndDate?: Dayjs | null;
  minCallDuration?: number;
  endDate?: Dayjs | null;
  uid?: string;
  callResult?: CallResult | ManualOutboundCallResult | "";
  callListId?: string;
  scriptId?: string;
  keyword?: string;
  phoneNumber?: string;
};

// ページネーション用のクラス
class CallHistoryCursor {
  readonly filter: CallHistoryFilter;
  readonly callsPerPage: number;
  private offset?: number;

  constructor({
    filter,
    callsPerPage,
  }: {
    filter: CallHistoryFilter;
    callsPerPage?: number;
  }) {
    this.filter = filter;
    this.callsPerPage = callsPerPage || DEFAULT_QUERY_LIMIT;
  }

  totalRecordsNum = async (): Promise<number> => {
    const filter = this.filter;

    const res = await request({
      path: "/calls",
      httpMethod: "get",
      params: {
        query: {
          limit: this.callsPerPage,
          offset: 0 * this.callsPerPage,
          includeQueued: false,
          createdAtStart: filter.startDate
            ? filter.startDate.startOf("date").toISOString()
            : undefined,
          createdAtEnd: filter.endDate
            ? filter.endDate.endOf("date").toISOString()
            : undefined,
          noteResult: !filter.callResult || "" ? undefined : filter.callResult,
          contactListId: filter.callListId || undefined,
          scriptId: filter.scriptId || undefined,
          durationMin: filter.minCallDuration || undefined,
          durationMax: undefined,
          assigneeId: filter.uid || undefined,
          status: undefined,
          nextCallScheduledAtStart: filter.nextCallStartDate
            ? filter.nextCallStartDate.startOf("date").toISOString()
            : undefined,
          nextCallScheduledAtEnd: filter.nextCallEndDate
            ? filter.nextCallEndDate.endOf("date").toISOString()
            : undefined,
          phoneNumber: filter.phoneNumber || undefined,
          query: filter.keyword || undefined,
          nextCallCompleted: undefined,
        },
      },
    });

    if (res.result === "error") return 0;

    return res.data.total;
  };

  fetchNext = async (
    count: number = 1,
  ): Promise<{ [historyId: string]: CallHistoryRecord }> => {
    const filter = this.filter;

    const res = await request({
      path: "/calls",
      httpMethod: "get",
      params: {
        query: {
          limit: this.callsPerPage || undefined,
          offset: (count - 1) * this.callsPerPage,
          includeQueued: false,
          createdAtStart: filter.startDate
            ? new Date(
                filter.startDate.toDate().setHours(0, 0, 0, 0),
              ).toISOString()
            : undefined,
          createdAtEnd: filter.endDate
            ? new Date(
                filter.endDate.toDate().setHours(24, 0, 0, 0),
              ).toISOString()
            : undefined,
          noteResult: !filter.callResult || "" ? undefined : filter.callResult,
          contactListId: filter.callListId || undefined,
          scriptId: filter.scriptId || undefined,
          durationMin: filter.minCallDuration || undefined,
          durationMax: undefined,
          assigneeId: filter.uid || undefined,
          status: undefined,
          nextCallScheduledAtStart: filter.nextCallStartDate
            ? new Date(
                filter.nextCallStartDate.toDate().setHours(0, 0, 0, 0),
              ).toISOString()
            : undefined,
          nextCallScheduledAtEnd: filter.nextCallEndDate
            ? new Date(
                filter.nextCallEndDate.toDate().setHours(24, 0, 0, 0),
              ).toISOString()
            : undefined,
          phoneNumber: filter.phoneNumber || undefined,
          query: filter.keyword || undefined,
          nextCallCompleted: undefined,
        },
      },
    });

    console.log("fetchNextが実行", res);
    if (res.result === "error") return {};

    console.log("res.data.calls: ", res.data.calls);

    const checkCallHistoryData = (
      data: "OUTBOUND" | "INBOUND",
    ): CallDirection => {
      return data === "OUTBOUND" ? "OUTGOING" : "INCOMING";
    };

    const checkResult = (
      data: string,
    ): CallResult | ManualOutboundCallResult => {
      if (!data) {
        return "UNREACHABLE";
      }
      return data as CallResult | ManualOutboundCallResult;
    };

    const callHistoriesData: CallHistoryRecord[] = res.data.calls.map(
      (call) => {
        return {
          id: call.id,
          companyName: call.toContactName || "",
          createdAt: call.createdAt,
          updatedAt: call.updatedAt,
          calledAt: call.calledAt,
          callDirection: checkCallHistoryData(call.direction),
          callDuration: call.duration,
          callMemo: "",
          result: checkResult(call.noteResult),
          phoneNumber: call.to || "",
          audioPath: "",
          transcriptPath: "",
          operatorId: call.assigneeId,
          scriptId: call.scriptId,
          callListId: call.toContactId,
          callListIndex: 0,
          redialAttempts: 0,
          phoneCallId: call.phoneCallId,
        };
      },
    );

    const callHistories = callHistoriesData.map(
      (callHistoryData: CallHistoryRecord) => {
        return [callHistoryData.id, callHistoryData];
      },
    );

    return Object.fromEntries(callHistories);
  };

  getCallHistory = async (
    callHistoryId,
  ): Promise<{ callHistoryData: CallHistoryRecord }> => {
    const res = await request({
      path: `/calls/{callId}`,
      httpMethod: "get",
      params: {
        paths: {
          callId: callHistoryId,
        },
      },
    });

    const checkCallHistoryData = (
      data: "OUTBOUND" | "INBOUND",
    ): CallDirection => {
      return data === "OUTBOUND" ? "OUTGOING" : "INCOMING";
    };

    const checkResult = (
      data: string,
    ): CallResult | ManualOutboundCallResult => {
      if (!data) {
        return "UNREACHABLE";
      }
      return data as CallResult | ManualOutboundCallResult;
    };

    const callHistoryData: CallHistoryRecord = {
      id: res.data.id,
      companyName: res.data.toContactName || "",
      createdAt: res.data.createdAt,
      updatedAt: res.data.updatedAt,
      calledAt: res.data.calledAt,
      callDirection: checkCallHistoryData(res.data.direction),
      callDuration: res.data.duration,
      callMemo: "",
      result: checkResult(res.data.noteResult),
      phoneNumber: res.data.to || "",
      audioPath: "",
      transcriptPath: "",
      operatorId: res.data.assigneeId,
      scriptId: res.data.scriptId,
      callListId: res.data.toContactId,
      callListIndex: 0,
      redialAttempts: 0,
      phoneCallId: res.data.phoneCallId,
    };

    return { callHistoryData };
  };
}

export { CallHistoryCursor };
export type { CallHistoryFilter };
