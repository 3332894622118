import { CallListItem, CallListMetadata } from "@/models/CallList";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Call, CallWithRedialAttempts } from "@/models/Call";
import { CallHistoryRecord } from "@/models/CallHistory";
import { Call as TwilioCall, Device } from "@twilio/voice-sdk";
import { CallBatch } from "@/models/CallBatch";
import { Script, ScriptLines } from "@/models/Script";
import { request } from "@/models/telai-backend/client";
import type { CallHistoryTranscript } from "@/models/CallHistory";
import { downloadFromStorage } from "@/google/storage";

type CallState = {
  callProcesses: {
    [docId: string]: CallBatch;
  };
  callListMetadata: {
    [docId: string]: CallListMetadata;
  };
  callListItems: {
    [callListId: string]: {
      [docId: string]: CallListItem;
    };
  };
  callListItemsUploadProgress: number;
  callHistoryCurrentPage: number;
  callHistoryPageIsFetched: boolean[];
  filteredCallHistoryNum: number;
  callHistory: {
    [docId: string]: CallHistoryRecord;
  };
  calls: {
    [id: string]: Call;
  };
  filteredCallHistory: {
    [docId: string]: CallHistoryRecord;
  };
  transcripts: {
    id: string | null;
    transcript: CallHistoryTranscript[];
  };
  scripts: {
    [docId: string]: Script;
  };
  scriptLines: {
    [docId: string]: ScriptLines;
  };
  manualRedialCalls: {
    [callSid: string]: CallWithRedialAttempts;
  };
  aiRedialCalls: {
    [callSid: string]: CallWithRedialAttempts;
  };

  // 通話
  callDevice: Device | null;
  twilioToken: string | null;
  twilioCall?: TwilioCall;
  currentCallId?: string;
  assignedPid: number;
};

const initialState: CallState = {
  callProcesses: {},
  callListMetadata: {},
  callListItems: {},
  callListItemsUploadProgress: 0,
  callHistory: {},
  filteredCallHistory: {},
  filteredCallHistoryNum: 0,
  callHistoryCurrentPage: 1,
  callHistoryPageIsFetched: [],
  transcripts: {
    id: null,
    transcript: [],
  },
  scripts: {},
  scriptLines: {},
  manualRedialCalls: {},
  aiRedialCalls: {},
  calls: {},
  callDevice: null,
  twilioToken: null,
  twilioCall: null,
  currentCallId: null,
  assignedPid: -1,
};

export const getCallListMetadata = createAsyncThunk<
  CallListMetadata[],
  string,
  { state: RootState }
>("call/getCallListMetadata", async () => {
  const res = await request({
    path: "/contact_lists",
    httpMethod: "get",
  });

  if (res.result === "error") {
    console.error(res.error);
    return;
  }

  return res.data.contactLists.map((list) => ({
    name: list.name,
    creatorId: list.contactUpdatedAt,
    id: list.id,
    optionalInfoOrder: list.metadataKeys,
    memo: list.description,
    searchWord: list.searchKeyword,
    createdAt: new Date(list.createdAt),
    updatedAt: new Date(list.updatedAt),
  }));
});

export const deleteCallList = createAsyncThunk<string, { id: string }>(
  "call/deleteCallList",
  async ({ id }) => {
    await request({
      path: "/contact_lists/{listId}",
      httpMethod: "delete",
      params: {
        paths: { listId: id },
      },
    });
    console.debug("call list deleted");
    return id;
  },
);

export const updateCallList = createAsyncThunk<
  { metadata: CallListMetadata; items?: CallListItem[]; id: string },
  {
    id: string;
    items?: CallListItem[];
    addCallListItem?: CallListItem;
    metadata: CallListMetadata;
  }
>(
  "call/updateCallList",
  async ({ id, items, metadata, addCallListItem }, { rejectWithValue }) => {
    console.log(
      "updateCallList",
      id,
      "items",
      items,
      "addCallListItem",
      addCallListItem,
      "meta",
      metadata,
    );
    if (!metadata)
      rejectWithValue({
        message: `metadata doesn't exist in local (id: ${id})`,
      });

      
      console.log("updateCallList", id, "items", items, metadata);
    const searchKeyword = metadata.searchWord.split(" ");
    await request({
      path: "/contact_lists/{listId}",
      httpMethod: "put",
      params: {
        paths: { listId: id },
        body: {
          name: metadata.name,
          description: metadata.memo,
          searchKeyword: metadata.searchWord,
          metadataKeys:searchKeyword,
      },
    } 
    });

    if (addCallListItem) {
      await request({
        path: "/contact_lists/{listId}/contacts",
        httpMethod: "post",
        params: {
          paths: { listId: id },
          body: {
            name: addCallListItem.companyName,
            phoneNumber: addCallListItem.phoneNumber,
            metadata: addCallListItem.optionalInfo,
          },
        },
      });
    }

    console.debug("call list updated", metadata);

    return { metadata, items, id };
  },
);

export const deleteCallListItems = createAsyncThunk<
  { callListId: string; callListItemIds: string[] },
  { callListId: string; indexes: number[] }
>("call/deleteCallListItem", async ({ callListId }) => {
  const results = await request({
    path: "/contact_lists/{listId}",
    httpMethod: "delete",
    params: {
      paths: { listId: callListId },
    },
  });
  console.log("deleted call list items", results);

  return {
    callListId,
    callListItemIds: [],
  };
});

export const getTranscript = createAsyncThunk<
  { id: string; transcript: CallHistoryTranscript[] } | void,
  { callHistoryId: string; companyId: string },
  { state: RootState }
>("call/getTranscript", async ({ callHistoryId, companyId }, { getState }) => {
  const call = getState().call;
  if (call.transcripts[callHistoryId]) return;

  const transcript = await request({
    path: "/calls/{callId}/transcriptions",
    httpMethod: "get",
    params: {
      paths: { callId: callHistoryId },
    },
  });

  // トランスクリプトがない場合はストレージから取得
  if (transcript.data.transcriptions.length === 0) {
    const res = await request({
      httpMethod: "get",
      path: "/calls/{callId}",
      params: {
        paths: { callId: callHistoryId },
      },
    });

    if (!res.data) return { id: callHistoryId, transcript: [] };

    const path = `company/${companyId}/log/transcript/${res.data.phoneCallId}.txt`;
    const googleStorageTranscript = (await downloadFromStorage({
      path,
      responseType: "text",
    })) as string;
    if (googleStorageTranscript) {
      const googleStorageTranscriptArray: CallHistoryTranscript[] =
        googleStorageTranscript.split("\n").map((line) => {
          line = line.replace(/[\s]/g, "");
          const timestamp = 0;
          let speaker = line.split(":")[0];
          const content = line.split(":")[1];
          if (speaker) {
            if (speaker === "Callee") {
              speaker = "FROM";
            } else {
              speaker = "TO";
            }
          }
          console.log("speaker", speaker, "content", content);

          return { timestamp: Number(timestamp), speaker, content };
        });
      return { id: callHistoryId, transcript: googleStorageTranscriptArray };
    } else {
      return { id: callHistoryId, transcript: [] };
    }
  }

  return { id: callHistoryId, transcript: transcript.data.transcriptions };
});

export const getCallHistoryById = createAsyncThunk<
  CallHistoryRecord,
  { companyId: string; callHistoryId: string },
  { state: RootState }
>("call/getCallHistoryById", async ({ callHistoryId }) => {
  const res = await request({
    path: "/calls/{callId}",
    httpMethod: "get",
    params: {
      paths: { callId: callHistoryId },
    },
  });

  const judgeCallDirection = (direction: string): "OUTGOING" | "INCOMING" => {
    switch (direction) {
      case "INCOMING":
        return "INCOMING";
      case "OUTGOING":
        return "OUTGOING";
      default:
        return "OUTGOING";
    }
  };

  const callHistoryRecode: CallHistoryRecord = {
    id: res.data.id,
    createdAt: res.data.createdAt,
    updatedAt: res.data.updatedAt,
    calledAt: res.data.calledAt,
    callDirection: judgeCallDirection(res.data.direction),
    callDuration: res.data.duration,
    callMemo: "callMemo",
    result: res.data.noteResult as CallHistoryRecord["result"],
    nextCalled: res.data.nextCallCompleted,
    nextCallDate: new Date(res.data.nextCallScheduledAt),
    prevCallSid: res.data.from,
    phoneNumber: res.data.to,
    audioPath: "",
    transcriptPath: "",
    operatorId: res.data.assigneeId,
    scriptId: res.data.scriptId,
    callListId: "",
    callListIndex: 0,
    manualCall: false,
    redialAttempts: 0,
    companyName: "",
    phoneCallId: res.data.phoneCallId,
  };

  return callHistoryRecode;
});

export const addCallHistory = createAsyncThunk<
  { docId: string; record: CallHistoryRecord },
  { companyId: string; record: CallHistoryRecord }
>("call/addCallHistory", async ({ record }) => {
  const res = await request({
    path: "/calls",
    httpMethod: "post",
    params: {
      body: {
        from: record.phoneNumber,
        to: record.phoneNumber,
        scriptId: record.scriptId,
      },
    },
  });

  return {
    docId: res.data.id,
    record: { ...record, id: res.data.id },
  };
});

export const getTwilioToken = createAsyncThunk<string>(
  "call/getTwilioToken",
  async () => {
    const res = await request({
      httpMethod: "get",
      path: "/twilio/token",
    });

    return res.data.token;
  },
);

export const getCalls = createAsyncThunk<Call[], void>(
  "call/getCalls",
  async () => {
    const res = await request({
      httpMethod: "get",
      path: "/calls",
    });

    return res.data.calls;
  },
);

export const getCall = createAsyncThunk<Call, { callId: string }>(
  "call/getCall",
  async ({ callId }) => {
    const res = await request({
      httpMethod: "get",
      path: "/calls/{callId}",
      params: {
        paths: { callId },
      },
    });

    return res.data;
  },
);

export const setCurrentCall = createAsyncThunk<
  Call | null,
  { callId?: string }
>("call/setCurrentCall", async ({ callId }) => {
  if (callId === null || callId === undefined) return null;

  const res = await request({
    httpMethod: "get",
    path: "/calls/{callId}",
    params: {
      paths: { callId: callId },
    },
  });

  return res.data;
});

export const getCallProcess = createAsyncThunk<
  CallBatch,
  { id: string },
  { rejectValue: { message: string } }
>(
  "user/getCallBatch",
  async ({ id }, { rejectWithValue }): Promise<CallBatch> => {
    const res = await request({
      path: "/call_batches/{batchId}",
      httpMethod: "get",
      params: {
        paths: { batchId: id },
      },
    });
    if (res.result === "error") {
      console.error(res.error);
      rejectWithValue(res.error.data);
      return;
    }

    return { ...res.data };
  },
);

export const getCallProcesses = createAsyncThunk<
  { [pid: string]: CallBatch },
  void,
  { rejectValue: { message: string } }
>("user/getCallBatches", async (_, { rejectWithValue }) => {
  const res = await request({
    path: "/call_batches",
    httpMethod: "get",
  });
  if (res.result === "error") {
    console.error(res.error);
    rejectWithValue(res.error.data);
    return;
  }

  console.debug(`fetched ${res.data.callBatches.length} batches`);
  return Object.fromEntries(
    res.data.callBatches.map((batch: CallBatch) => [batch.id, batch]),
  );
});

export const deleteCallProcess = createAsyncThunk<
  string,
  { companyId: string; docId: string }
>("call/deleteCallProcess", async ({ companyId, docId }) => {
  console.log("deleteCallProcess", companyId, docId);
  // await deleteDoc(`/companies/${companyId}/callProcesses`, String(docId))
  // return docId

  // 一時的
  return "none";
});

export const getScripts = createAsyncThunk<{ [scriptId: string]: Script }>(
  "call/getScripts",
  async () => {
    const res = await request({
      path: "/scripts",
      httpMethod: "get",
    });

    if (res.result === "error") {
      console.error(res.error);
      return;
    }

    const scripts = res.data.scripts.map((script) => [script.id, script]);
    console.debug(`fetched ${scripts.length} scripts`);
    return Object.fromEntries(scripts);
  },
);

export const getScriptLines = createAsyncThunk<
  { scriptLines: ScriptLines; scriptId: string },
  { scriptId: string }
>("call/getScriptLines", async ({ scriptId }) => {
  console.log("scriptId", scriptId);
  const res = await request({
    path: "/scripts/{scriptId}/messages",
    httpMethod: "get",
    params: {
      paths: { scriptId },
    },
  });

  if (res.result === "error") {
    console.error(res.error);
    return;
  }

  console.debug("fetched script lines", res.data.messages);

  return {
    scriptLines: res.data.messages,
    scriptId: scriptId,
  };
});

export const updateScript = createAsyncThunk<
  { scriptId: string; script: Partial<Script> },
  { companyId: string; scriptId: string; script: Partial<Script> }
>("call/updateScript", async ({ scriptId, script }) => {
  // await request({
  //     path: "/scripts/",
  //     httpMethod: ""
  // })

  return { scriptId, script };
});

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setCallDevice: (state, action: PayloadAction<Device>) => {
      state.callDevice = action.payload;
    },
    setTwilioCall: (state, action: PayloadAction<TwilioCall>) => {
      state.twilioCall = action.payload;
    },
    setAssignedPid: (state, action: PayloadAction<number>) => {
      state.assignedPid = action.payload;
    },
    initCallSlice: (state) => {
      Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
        state[key] = value;
      });
      console.debug("call slice initialized");
    },
    updateCallProcesses: (
      state,
      action: PayloadAction<[string, CallBatch]>,
    ) => {
      state.callProcesses = {
        ...state.callProcesses,
        [action.payload[0]]: action.payload[1],
      };
      console.debug(`call process (pid: ${action.payload[0]}) refetched`);
    },
    deleteLocalCallProcess: (state, action: PayloadAction<string>) => {
      // immutableに更新しないと変更が反映されないため、直接のdeleteは避ける
      const newCallProcesses = { ...state.callProcesses };
      delete newCallProcesses[action.payload];
      state.callProcesses = newCallProcesses;
      console.debug(`call process (pid: ${action.payload}) deleted`);
    },
    updateLocalCallHistory: (
      state,
      action: PayloadAction<{ [id: string]: CallHistoryRecord }>,
    ) => {
      console.log(state.callHistory, action.payload);
      state.callHistory = {
        ...action.payload,
      };
      state.filteredCallHistory = {
        ...action.payload,
      };
    },
    initCallHistory: (state) => {
      state.callHistory = {};
      state.filteredCallHistory = {};
    },
    setFilteredCallHistoryNum: (state, action: PayloadAction<number>) => {
      state.filteredCallHistoryNum = action.payload;
    },
    setCallHistoryCurrentPage: (state, action: PayloadAction<number>) => {
      state.callHistoryCurrentPage = action.payload;
    },
    setCallHistoryPageIsFetched: (state, action: PayloadAction<boolean[]>) => {
      state.callHistoryPageIsFetched = action.payload;
    },
    updateLocalCallListMetadata: (
      state,
      action: PayloadAction<CallListMetadata[]>,
    ) => {
      action.payload.forEach((metadata) => {
        state.callListMetadata[metadata.id] = metadata;
      });
    },
    updateLocalCallListItems: (
      state,
      action: PayloadAction<CallListItem[]>,
    ) => {
      console.log("updateLocalCallListItems", action.payload);
      action.payload.forEach((item) => {
        if (!state.callListItems[item.callListId])
          state.callListItems[item.callListId] = {};
        state.callListItems[item.callListId][item.id] = item;
      });
      console.log("updated callListItems state:", state.callListItems);
    },
    updateAiRedialCalls: (
      state,
      action: PayloadAction<CallWithRedialAttempts[]>,
    ) => {
      action.payload.forEach((record) => {
        state.aiRedialCalls[record.id] = record;
      });
    },
    updateManualRedialCalls: (
      state,
      action: PayloadAction<CallWithRedialAttempts[]>,
    ) => {
      action.payload.forEach((record) => {
        state.manualRedialCalls[record.id] = record;
      });
    },
    deleteManualRedialCalls: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        delete state.manualRedialCalls[id];
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(getCallListMetadata.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCallListMetadata.fulfilled, (state, action) => {
      state.callListMetadata = Object.fromEntries(
        action.payload.map((data) => [data.id, data]),
      );
      state.callListItemsUploadProgress = 0;
    });

    builder.addCase(updateCallList.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(updateCallList.fulfilled, (state, action) => {
      state.callListMetadata[action.payload.id] = action.payload.metadata;
      if (action.payload.items) {
        action.payload.items.forEach((item) => {
          state.callListItems[action.payload.id][item.id] = item;
        });
      }
    });

    builder.addCase(deleteCallListItems.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(deleteCallListItems.fulfilled, (state, action) => {
      const callListId = action.payload.callListId;
      action.payload.callListItemIds.forEach((id) => {
        delete state.callListItems[callListId][id];
      });
    });

    builder.addCase(deleteCallList.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(deleteCallList.fulfilled, (state, action) => {
      const id = action.payload;
      delete state.callListMetadata[id];
      delete state.callListItems[id];
    });

    builder.addCase(getTranscript.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getTranscript.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { id, transcript } = action.payload;
      state.transcripts[id] = { id, transcript };
    });

    builder.addCase(getCallHistoryById.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCallHistoryById.fulfilled, (state, action) => {
      state.callHistory[action.payload.id] = action.payload;
    });

    builder.addCase(addCallHistory.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(addCallHistory.fulfilled, (state, action) => {
      state.callHistory[action.payload.docId] = action.payload.record;
    });

    builder.addCase(getTwilioToken.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getTwilioToken.fulfilled, (state, action) => {
      state.twilioToken = action.payload;
    });

    builder.addCase(getCalls.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCalls.fulfilled, (state, action) => {
      action.payload.forEach((call) => {
        state.calls[call.id] = call;
      });
    });

    builder.addCase(getCall.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCall.fulfilled, (state, action) => {
      state.calls[action.payload.id] = action.payload;
    });

    builder.addCase(setCurrentCall.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(setCurrentCall.fulfilled, (state, action) => {
      if (action.payload === null) {
        state.currentCallId = null;
        return;
      }

      state.calls[action.payload.id] = action.payload;
      state.currentCallId = action.payload.id;
    });

    builder.addCase(getCallProcess.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCallProcess.fulfilled, (state, action) => {
      state.callProcesses[action.payload.id] = action.payload;
    });

    builder.addCase(getCallProcesses.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getCallProcesses.fulfilled, (state, action) => {
      state.callProcesses = action.payload;
    });

    builder.addCase(deleteCallProcess.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(deleteCallProcess.fulfilled, (state, action) => {
      delete state.callProcesses[action.payload];
    });

    builder.addCase(getScripts.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getScripts.fulfilled, (state, action) => {
      state.scripts = action.payload;
    });

    builder.addCase(getScriptLines.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(getScriptLines.fulfilled, (state, action) => {
      state.scriptLines[action.payload.scriptId] = action.payload.scriptLines;
    });

    builder.addCase(updateScript.rejected, (state, action) => {
      console.error(action.error.message);
    });
    builder.addCase(updateScript.fulfilled, (state, action) => {
      state.scripts[action.payload.scriptId] = {
        ...state.scripts[action.payload.scriptId],
        ...action.payload.script,
      };
    });
  },
});

export const {
  setCallDevice,
  setTwilioCall,
  setAssignedPid,
  updateCallProcesses,
  deleteLocalCallProcess,
  initCallSlice,
  updateLocalCallHistory,
  initCallHistory,
  setFilteredCallHistoryNum,
  setCallHistoryCurrentPage,
  setCallHistoryPageIsFetched,
  updateLocalCallListItems,
  updateLocalCallListMetadata,
  updateAiRedialCalls,
  updateManualRedialCalls,
  deleteManualRedialCalls,
} = callSlice.actions;
export default callSlice.reducer;
