import {
  CallResult,
} from "@/models/CallHistory";
import { Call, CallWithRedialAttempts } from "@/models/Call";
import { request } from "@/models/telai-backend/client";
import dayjs from "dayjs";

// callHistoryのprevCallSidを辿れる回数を再コール回数として返す
const countRedialAttempts = async (
  call: Call
) => {
  const res = await request({
    path: "/contacts/{contactId}",
    httpMethod: "get",
    params: {
      paths: { contactId: call.toContactId }
    }
  });
  if(res.result === "error") {
    throw new Error(res.error.data.message);
  }
  const relatedCallIds = res.data.relatedCallIds.filter(id => id !== call.id);
  const relatedCalls = await Promise.all(
    relatedCallIds.map(async (id) => (await request({
      path: "/calls/{callId}",
      httpMethod: "get",
      params: {
        paths: { callId: id }
      }
    })).data)
  )
  const relatedPrevCalls = relatedCalls.filter(relatedCall => dayjs(relatedCall.calledAt).isBefore(dayjs(call.calledAt)));
  return relatedPrevCalls.length
};

const getRedialCalls = async ({
  callResultsToRedial,
  redialMaxAttempts,
  redialIntervalDays,
  nextCallScheduledAtStart,
  nextCallScheduledAtEnd
}: {
  callResultsToRedial: CallResult[];
  redialMaxAttempts: { [key in CallResult]?: number };
  redialIntervalDays?: { [key in CallResult]?: number };
  nextCallScheduledAtStart?: dayjs.Dayjs;
  nextCallScheduledAtEnd?: dayjs.Dayjs;
}): Promise<CallWithRedialAttempts[]> => {
  const res = await request({
    path: "/calls",
    httpMethod: "get",
    params: {
      query: {
        nextCallCompleted: false,
        nextCallScheduledAtStart: nextCallScheduledAtStart?.toISOString() || undefined,
        nextCallScheduledAtEnd: nextCallScheduledAtEnd?.toISOString() || undefined,
        limit: 100,
      }
    }
  });
  if(res.result === "error") {
    throw new Error(res.error.data.message)
  }
  console.log("getRedialCalls", res.data.calls)

  const calls: Call[] = res.data.calls.filter(call =>
    call.toContactId && callResultsToRedial.includes(call.noteResult)
  );

  // redialIntervalDaysが設定されている場合は指定日数経過しているもののみ再架電対象とする
  const callsToRedial = calls.filter((call) => {
    if(!redialIntervalDays || !redialIntervalDays[call.noteResult]) {
      return true
    }
    return dayjs(call.calledAt).isBefore(dayjs().subtract(redialIntervalDays[call.noteResult], "day").endOf("date"))
  })
  
  // redialAttemptsを数える
  const callsWithRedialAttempts = await Promise.all(
    callsToRedial.map(async (call) => ({
      ...call,
      redialAttempts: await countRedialAttempts(call),
    })),
  );

  // redialAttempts以上再架電されたものは除外
  // nextCallHistoryIdから参照
  const isAttemptedMaxTimes = await Promise.all(
    callsWithRedialAttempts.map((call) => {
      if (
        !redialMaxAttempts[call.noteResult] ||
        redialMaxAttempts[call.noteResult] <= 0
      ) {
        throw new Error(
          `redialMaxAttempts must be greater than 0 (key: ${call.noteResult}, value: ${redialMaxAttempts[call.noteResult]})`,
        );
      }
      return redialMaxAttempts[call.noteResult] <= call.redialAttempts;
    })
  );
  const filtered = callsWithRedialAttempts.filter(
    (_, index) => !isAttemptedMaxTimes[index],
  );
  console.log("filtered", filtered)

  return filtered;
};

export { getRedialCalls };
