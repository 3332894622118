import { CallHistoryRecord } from "@/models/CallHistory";
import {
  MoreHoriz,
  PhoneCallback,
  PhoneForwarded,
  PlayArrow,
  QuestionMark,
  Stop,
} from "@mui/icons-material";
import { Box, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useEffect, useMemo, useRef, useState } from "react";
import { request } from "@/models/telai-backend/client";
import dayjs from "dayjs";

export const CallHistoryEntry = ({ record }: { record: CallHistoryRecord }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const callResultLabels = useSelector(
    (state: RootState) => state.company.config.customCallResultLabels,
  );
  const [isPlaying, setIsPlaying] = useState(false);

  /**
   * 通話時間を分と秒に分ける
   * @returns mm分ss秒の形式で返す
   */
  const callDuration = useMemo(() => {
    if (record.callDuration === null || record.callDuration === undefined)
      return "-";
    return `${Math.floor(record.callDuration / 60).toFixed(0)}分${(record.callDuration % 60).toFixed(0)}秒`;
  }, [record.callDuration, record.result]);

  const handleTogglePlay = async () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      try {
        if (!audioRef.current) {
          const res = await request({
            path: `/calls/{callId}/recording`,
            httpMethod: "get",
            responseType: "blob",
            params: {
              paths: {
                callId: record.id,
              },
            },
          });
          if (res.result === "success") {
            const audioBlob = res.data;
            const url = URL.createObjectURL(audioBlob);
            const audioElement = new Audio(url);
            audioRef.current = audioElement;
            audioElement.play();
            audioElement.onended = () => {
              URL.revokeObjectURL(url);
              audioRef.current = null;
            };
          }
        } else {
          audioRef.current.play();
        }
        setIsPlaying(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };
  }, [location]);

  useEffect(() => {
    const handleEnded = () => setIsPlaying(false);
    audioRef.current?.addEventListener("ended", handleEnded);
    return () => audioRef.current?.removeEventListener("ended", handleEnded);
  }, []);

  // const getCallDirectionIcon = () => {
  //   switch (record.callDirection) {
  //     case "INCOMING":
  //       return <PhoneCallback fontSize="small" htmlColor="#7FB50B" />
  //     case "OUTGOING":
  //       return <PhoneForwarded fontSize="small" htmlColor="#E15E14" />
  //     default:
  //       return <QuestionMark fontSize="small" color="inherit" />
  //   }
  // }

  // const getCallDirectionText = () => {
  //   switch (record.callDirection) {
  //     case "INCOMING":
  //       return "受信"
  //     case "OUTGOING":
  //       return "発信"
  //     default:
  //       return "その他"
  //   }
  // }

  /**
   * 電話場番号の+81を0に変換する
   * @param {string} phoneNumber 電話番号
   * @returns {string} 変換後の電話番号
   */
  const convertPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/^\+81/, "0");
  };

  return (
    <Paper sx={{ px: 5, py: 1, borderRadius: 3 }}>
      <Grid container columns={15} spacing={1}>
        <Grid
          item
          xs={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack>
            {record.callDirection === "INCOMING" ? (
              <PhoneCallback htmlColor="#7FB50B" sx={{ mx: "auto" }} />
            ) : record.callDirection === "OUTGOING" ? (
              <PhoneForwarded htmlColor="#E15E14" sx={{ mx: "auto" }} />
            ) : (
              <QuestionMark sx={{ mx: "auto" }} color="inherit"></QuestionMark>
            )}
            <Typography fontSize="0.875rem">
              {record.callDirection === "INCOMING"
                ? "受信"
                : record.callDirection === "OUTGOING"
                  ? "発信"
                  : "その他"}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            {convertPhoneNumber(record.phoneNumber || "")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {record.companyName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack>
            <Box>{dayjs(record.calledAt).format("YYYY-MM-DD")}</Box>
            <Box>{dayjs(record.calledAt).format("HH:mm:ss")}</Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            {callResultLabels[record.result] || record.result}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{callDuration}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton onClick={handleTogglePlay} size="medium" sx={{ mr: 1 }}>
            {isPlaying ? (
              <Stop fontSize="small" />
            ) : (
              <PlayArrow fontSize="small" />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            onClick={() =>
              navigate(`/${tenantId}/history/${record.id || "xxx"}`)
            }
          >
            <MoreHoriz fontSize="large"></MoreHoriz>
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};
